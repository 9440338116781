import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import type { OverlayEventDetail } from '@ionic/core/components';
import { useIonAlert, useIonModal, useIonRouter } from '@ionic/react';
import { Fragment, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import { AddUserToCrewModal } from '../../components/AddUserToCrewModal/AddUserToCrewModal';
import { ChangeUserRoleModal } from '../../components/ChangeUserRoleModal/ChangeUserRoleModal';
import languageOptions from '../../data/languageOptions.json';
import { DashboardFilterView, EmployeeLevelId } from '../../enums';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { Content } from '../../layout/content/content';
import { Header } from '../../layout/header/Header/Header';
import type { SetEmployeeLevelOnAllCrews } from '../../models/setEmployeeLevelOnAllCrews.model';
import { canChangeOwnEmployeeLevel, getEmployeeLevelOnCrew, hasEmployeeLevelSupervisorOnAnyCrew } from '../../services/common/profile.utils';
import {
  useGetMyProfileQuery,
  useSetEmployeeLevelOnAllCrewsMutation,
  useUpdateMyProfileMutation
} from '../../services/data/profile.service';
import { setFilterView } from '../Dashboard/components/DashboardFilters/DashboardFiltersSlice';

import './Settings.scss';
import { setLang, toggleDarkmode } from './SettingsSlice';

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ');
}

export const Settings: React.FC = () => {
  const router = useIonRouter();
  const dispatch = useAppDispatch();

  const [selected, setSelected] = useState(languageOptions[0]);
  const isDarkMode = useAppSelector((state) => state.settings.darkmode);
  const selectedSiteId: any = useAppSelector((state) => state.auth.siteId);
  const lang = useAppSelector((state) => state.settings.lang);
  const isOnline = useAppSelector((state) => state.connectivityStatus.isOnline);
  const [canModifyOwnEmployeeLevel, setCanModifyOwnEmployeeLevel] = useState(false);

  const { t, i18n } = useTranslation();
  const { data: userProfile, error, isLoading, refetch } = useGetMyProfileQuery();
  const [updateMyProfile, response] = useUpdateMyProfileMutation();
  const [setEmployeeLevelOnAllCrews, setEmployeeLevelOnAllCrewsResult] = useSetEmployeeLevelOnAllCrewsMutation();

  const [presentAlert, hideAlert] = useIonAlert();

  const [present, dismiss] = useIonModal(AddUserToCrewModal, {
    siteId: selectedSiteId,
    onDismiss: (data: string, role: string) => dismiss(data, role),
  });

  const [presentChangeRoleDialog, dismissChangeRoleDialog] = useIonModal(ChangeUserRoleModal, {
    onDismiss: (data: string, role: string) => dismissChangeRoleDialog(data, role),
  });

  const updateLang = (langOption: any) => {
    dispatch(setLang(langOption.lang));
    i18n.changeLanguage(langOption.lang);
    setSelected(langOption);
    toast.success(t('FEATURES.SETTINGS.LANG_CHANGED_SUCCESSFULLY'), { duration: 2000, position: 'top-center' });
  };

  const handleGenerateWorkcard = (e: any) => {
    router.push('/settings/generate-demo-workcard', 'forward');
  };

  const handleAddUserToCrew = (e: any) => {
    present({
      id: 'addUserToCrewDialog',
    });
  };

  const handleChangeUserRole = (e: any) => {
    presentChangeRoleDialog({
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.role === 'confirm') {
          if (ev.detail.data === userProfile?.id) {
            refetch();
          }
        }
      },
      id: 'changeUserRoleDialog',
    });
  };

  const handleChangeDarkmode = (e: any) => {
    dispatch(toggleDarkmode(e.target.checked));

    toast.success(
      t(`${e.target.checked ? 'FEATURES.SETTINGS.DARK_MODE_TURN_ON' : 'FEATURES.SETTINGS.DARK_MODE_TURN_OFF'}`),
      {
        duration: 1500,
        position: 'top-center',
      }
    );
  };

  const handleEmployeeLevelChanged = (ev: any) => {
    const employeeLevel = ev.value;

    const payload: SetEmployeeLevelOnAllCrews =
    {
      employeeLevel: employeeLevel
    };

    setEmployeeLevelOnAllCrews(payload);

    if (employeeLevel === EmployeeLevelId.Worker) {
      dispatch(setFilterView(DashboardFilterView.myCrews));
    }

    hideAlert();
  };

  const isProduction = process.env.REACT_APP_ENV === 'PROD';

  useEffect(() => {
    if (setEmployeeLevelOnAllCrewsResult?.isSuccess) {
      refetch();
      toast.success(t('FEATURES.SETTINGS.EMPLOYEE_LEVEL_CHANGED_SUCCESSFULLY'), { duration: 2000, position: 'top-center' });
    }
  }, [setEmployeeLevelOnAllCrewsResult]);

  useEffect(() => {
    languageOptions.map((languageOption) => {
      if (languageOption.lang === lang) {
        setSelected(languageOption);
      }
    });

    refetch();
  }, []);

  useEffect(() => {
    const userCanChangeOwnEmployeeLevel = canChangeOwnEmployeeLevel(userProfile);
    setCanModifyOwnEmployeeLevel(userCanChangeOwnEmployeeLevel);
  }, [isOnline, userProfile]);

  return (
    <>
      <Content isVerticalScroll={true} header={<Header title={t('FEATURES.SETTINGS.SETTINGS')} hasProfile={true} />}>
        <div>
          <div className="profile-detail mb-10 pb-[80px]">
            <div className="mt-6 max-w-5xl mx-auto px-6 sm:px-6 lg:px-8">
              <div>
                <h3 className="text-lg leading-6 font-bold text-gray-900 dark:text-gray-100 mb-6">
                  <i className="fa-solid fa-user mr-2"></i>
                  {t('FEATURES.SETTINGS.PROFILE')}
                </h3>
              </div>

              <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                <div className="sm:col-span-1">
                  <dt className="text-base font-medium text-gray-500 dark:text-gray-400">
                    {t('FEATURES.SETTINGS.DISPLAY_NAME')}
                  </dt>
                  <dd className="mt-1 text-base text-gray-900 dark:text-gray-100">
                    {userProfile?.name || t('LABELS.SELECT')}
                  </dd>
                </div>

                <div className="sm:col-span-1">
                  <dt className="text-base font-medium text-gray-500 dark:text-gray-400">
                    {t('FEATURES.SETTINGS.JOB_TITLE')}
                  </dt>
                  <dd className="mt-1 text-base text-gray-900 dark:text-gray-100">
                    {userProfile?.jobPosition || t('LABELS.SELECT')}
                  </dd>
                </div>
              </dl>

              <div className="mt-0">
                <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100 mb-6 mt-10">
                  <i className="fa-solid fa-users mr-2"></i>
                  {t('FEATURES.SETTINGS.CREW')}
                </h3>
              </div>

              <div>
                <div className="mt-6 flow-root">
                  <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                    {userProfile &&
                      userProfile.crews.map((crew: any) => (
                        <li key={crew.id} className="col-span-1 divide-y divide-gray-200 rounded-lg bg-gray-100 shadow">
                          <div className="flex w-full items-center justify-between space-x-6 p-6">
                            <div className="flex-1 truncate">
                              <div className="flex items-center space-x-3">
                                <h3 className="truncate text-sm font-bold text-gray-900">{crew.name}</h3>
                                <span
                                  onClick={() =>
                                    presentAlert({
                                      header: t('FEATURES.SETTINGS.SELECT_ROLE'),
                                      buttons: [],
                                      inputs: [
                                        {
                                          label: t('FEATURES.SETTINGS.SUPERVISOR'),
                                          type: 'radio',
                                          value: EmployeeLevelId.Supervisor,
                                          checked: crew.crewMembers[0]?.employeeLevel === EmployeeLevelId.Supervisor,
                                          handler: (e) => handleEmployeeLevelChanged(e),
                                        },
                                        {
                                          label: t('FEATURES.SETTINGS.WORKER'),
                                          type: 'radio',
                                          value: EmployeeLevelId.Worker,
                                          checked: crew.crewMembers[0]?.employeeLevel === EmployeeLevelId.Worker,
                                          handler: (e) => handleEmployeeLevelChanged(e),
                                        },
                                      ],
                                    })
                                  }
                                  className={`${!isOnline || !canModifyOwnEmployeeLevel ? 'pointer-events-none bg-gray-200' : 'bg-blue-100'
                                    } cursor-pointer flex-shrink-0 inline-block items-center rounded-full  px-3 py-0.5 text-sm font-medium text-blue-800`}
                                >
                                  {crew.crewMembers[0]?.employeeLevel === EmployeeLevelId.Supervisor
                                    ? t('FEATURES.SETTINGS.SUPERVISOR')
                                    : t('FEATURES.SETTINGS.WORKER')}
                                </span>
                              </div>
                            </div>

                            <div className="h-10 w-10 flex-shrink-0 rounded-full bg-gray-300">
                              <span className="ml-2 relative top-1.5 font-medium">{crew?.crewMembers?.length}</span>
                              <i className="text-[12px] ml-0.5 top-[5px] relative fa-solid fa-user"></i>
                            </div>
                          </div>
                        </li>
                      ))}
                    {hasEmployeeLevelSupervisorOnAnyCrew(userProfile) && isOnline && (
                        <>
                          {!isProduction && (
                            <li className="col-span-1 divide-y divide-gray-200 rounded-lg bg-gray-100 shadow flex inline-flex items-center justify-center">
                              <button
                                onClick={(e) => handleGenerateWorkcard(e)}
                                type="button"
                                className="mx-4 inline-flex items-center justify-center rounded-md border border-transparent bg-blue-800 dark:bg-[#009cde] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700  sm:w-auto"
                              >
                                <i className="fa-solid fa-plus text-white mr-2"></i>
                                {t('FEATURES.CREW_MGMT.GENERATE_DEMO_WORKCARD')}
                              </button>
                            </li>
                          )}
                        </>
                      )}
                  </ul>
                </div>
              </div>
              {userProfile?.isAdministrator && isOnline && (
                <>
                  <div className="mt-0">
                    <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100 mb-6 mt-10">
                      <i className="fa-solid fa-user-shield mr-2"></i>
                      {t('FEATURES.SETTINGS.PERMISSIONS_SECTION')}
                    </h3>
                  </div>

                  <button
                    onClick={(e) => handleChangeUserRole(e)}
                    type="button"
                    className="mr-8 inline-flex items-center justify-center rounded-md border border-transparent bg-blue-800 dark:bg-[#009cde] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700  sm:w-auto"
                  >
                    <i className="fa-solid fa-shield-halved text-white mr-2"></i>
                    {t('FEATURES.SETTINGS.ADJUST_USER_PERMISSION')}
                  </button>


                  <button
                    onClick={(e) => handleAddUserToCrew(e)}
                    type="button"
                    className="mr-8 inline-flex items-center justify-center rounded-md border border-transparent bg-blue-800 dark:bg-[#009cde] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700  sm:w-auto"
                  >
                    <i className="fa-solid fa-plus text-white mr-2"></i>
                    Add user to crew
                  </button>
                </>
              )}

              <div className="mt-0">
                <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100 mb-6 mt-10">
                  <i className="fa-solid fa-gear mr-2"></i>
                  {t('FEATURES.SETTINGS.SETTINGS')}
                </h3>
              </div>

              <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                <div className="sm:col-span-1 flex mt-2">
                  <dt className="text-base font-medium text-gray-500 dark:text-gray-400 mr-8">
                    {t('FEATURES.SETTINGS.LANGUAGE')}
                  </dt>
                  <dd className="-mt-2 text-base text-gray-900">
                    <Listbox value={selected} onChange={updateLang}>
                      {({ open }) => (
                        <>
                          <div className="relative">
                            <div className="inline-flex divide-x divide-blue-600 rounded-md shadow-sm">
                              <div className="inline-flex divide-x divide-blue-600 rounded-md shadow-sm">
                                <div className="inline-flex items-center rounded-l-md border border-transparent bg-blue-700 dark:bg-[#009cde] py-2 pl-3 pr-4 text-white shadow-sm">
                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                  <p className="ml-2.5 text-sm font-medium">{selected.title}</p>
                                </div>
                                <Listbox.Button className="inline-flex items-center rounded-l-none rounded-r-md bg-blue-700 dark:bg-[#009cde] p-2 text-sm font-medium text-white hover:bg-blue-800 ">
                                  <ChevronDownIcon className="h-5 w-5 text-white" aria-hidden="true" />
                                </Listbox.Button>
                              </div>
                            </div>

                            <Transition
                              show={open}
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="absolute left-0 z-10 mt-2 w-[150px] origin-top-right divide-y divide-gray-200 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                {languageOptions.map((option) => (
                                  <Listbox.Option
                                    key={option.id}
                                    className={({ active }) =>
                                      classNames(
                                        active ? 'text-white bg-blue-700 dark:bg-[#009cde]' : 'text-gray-900',
                                        'cursor-default select-none p-4 text-sm'
                                      )
                                    }
                                    value={option}
                                  >
                                    {({ selected, active }) => (
                                      <div className="flex flex-col">
                                        <div className="flex justify-between">
                                          <p className={selected ? 'font-semibold' : 'font-normal'}>{option.title}</p>
                                          {selected ? (
                                            <span className={active ? 'text-white' : 'text-blue-500'}>
                                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                            </span>
                                          ) : null}
                                        </div>
                                      </div>
                                    )}
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </>
                      )}
                    </Listbox>
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </Content>
    </>
  );
};
